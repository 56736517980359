<template>
    <div>
      <div class="page-title pageheading" style="height: 50px">
        <h3 class="titlename">
          <i class="fas fa-user"></i><span class="ml-3">EVV Form Request </span>
        </h3>
      </div>
      <div>
        <div>
      <v-row>
      <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Search "
            counter
            filled
            rounded
            clearable
            dense
            maxlength="50"
          >  <template #label>
        <i class="fas fa-search"></i>  Search
      </template> </v-text-field>
        </v-col>
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn > Search </v-btn></v-col
        ></v-row
      >
    </div>  
      </div>
      <div 
        class="container mt-10"
        style="background-color: #ffffff"
      >
        <b-table
          class="table table-bordered"
          show-empty
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="0"
          striped
          hover
          select-mode="single"
          selectable
      
        >
        <template #cell(status)="row">
          <div class="status-indicator">
            <span :class="statusClass(row.item.status)"></span>
            <span>{{ row.item.status }}</span>
          </div>
        </template>
        <template #cell(options)="row">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="viewDetails(row.item)">
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(row.item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        </b-table>
        <div class="row">
          <div class="col-6">
            <b-pagination
              size="md"
              v-if="perPage != '-1'"
              :total-rows="totalItems"
              v-model="currentPage"
              :per-page="perPage"
            ></b-pagination>
          </div>
          <div class="col-6 text-right">
            <span style="margin-right: 10px"><label for="">Show : </label></span>
            <select
              v-model="perPage"
              class="form-select"
              style="border: groove; width: 6%"
              aria-label="Default select example"
              @change="Search()"
            >
              <option selected>50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="-1">All</option>
            </select>
  
            <span style="margin-left: 10px; margin-right: 20px">
              <label for=""> Entries </label></span
            ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
          </div>
        </div>
      </div>
      <!-- <div
        v-else
        class="container mt-10"
        style="background-color: #ffffff; text-align: center"
      >
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div> -->
  
     
    </div>
  </template>
  <script>

  export default {
 
    data() {
      return {
        isEditAble: true,
        isfetching: false,
        isRemoving: false,
        isSending: false,
        roleid: "",
        items: [
        {
          name: "Mike Johnson",
          date: "2023-05-22",
          message:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
          status: "Approved",
        },
        {
          name: "Mike Johnson",
          date: "2023-05-22",
          message:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
          status: "Approved",
        },
        {
          name: "Mike Johnson",
          date: "2023-05-22",
          message:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad",
          status: "Approved",
        },
      ],
        fields: [
          {
            key: "name",
            label: "Name",
          },
    
          {
            key: "date",
            label: "Date",
          },
          {
            key: "message",
            label: "Message",
          },
          {
            key: "status",
            label: "Status",
          },
         
          {
            key: "options",
            label: "Options",
          },
        ],
        currentPage: 1,
        perPage: 50,
        totalItems: 10,
        search: "",
        roleName: "",
        phoneNumber: "",
        location: "",
        id: "",
        type: "",
        caseworkers: [],
        rules: {
          required: (value) => !!value || "Role Name is Required.",
          counter: (value) => value.length <= 50 || "Max 50 characters",
        },
      };
    },
    methods: {
        statusClass(status) {
      if (status === "Green") {
        return "dot-green";
      } else if (status === "Yellow") {
        return "dot-yellow";
      } else if (status === "Red") {
        return "dot-red";
      } else {
        return "";
      }
    },
    viewDetails(item) {
      // Implement view details logic here
      console.log("Viewing details for", item);
    },
    deleteItem(item) {
      // Implement delete item logic here
      console.log("Deleting item", item);
    }
  },
    
  };
  </script>
  <style scoped>
  .error--text {
    color: rgba(252, 9, 9, 0.7) !important;
  }
  .titlename {
    float: left !important;
    padding-left: 19px;
  }
  .pageheading {
    padding-top: 20px;
    padding-bottom: 50px;
    height: 50px;
    background-color: #ffffff;
  }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 1px !important;
  }
  .table {
    box-shadow: 0 0 5px #ccc;
  }
  .row {
    margin: 0px !important;
  }
  .requiredfield {
    color: rgba(252, 9, 9, 0.7);
  }
  .form-group.invalid .invalid-feedback {
    display: block;
    text-align: initial;
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0;
    font-size: 80%;
    color: #dc3545;
  }
  
  .edit_btn{
    cursor: pointer !important;
    color: #757575 !important;
  }
  .edit_btn:hover {
    color: #FB8C00 !important;
  };
  .del_btn{
    cursor: pointer !important;
    color: #757575 !important;
  }
  .del_btn:hover {
    color: #BF360C !important;
  }
  .text-green-500 {
  color: #10b981 !important;
}
.text-yellow-500 {
  color: #f59e0b !important;
}
.dot-green {
  height: 10px;
  width: 10px;
  background-color: #10b981;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.dot-yellow {
  height: 10px;
  width: 10px;
  background-color: #f59e0b;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.dot-red {
  height: 10px;
  width: 10px;
  background-color: #f56565;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.status-indicator {
  display: flex;
  align-items: center;
}
  </style>
  